import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/common/layout";
import InformationNavbar from "../components/information/information-navbar";
import AgendaList from "../components/information/agenda-list";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query AgendaPageQuery($skip: Int!, $limit: Int!) {
    agendas: allSanityAgenda(
      skip: $skip
      limit: $limit
      sort: { fields: time, order: DESC }
      filter: { time: { ne: "null" } }
    ) {
      edges {
        node {
          id
          time(formatString: "dddd, DD MMM YYYY", locale: "id")
          title
          _rawEvent
          location
          attendee
        }
      }
    }
  }
`;

const AgendaPage = (props) => {
  const { data } = props;

  const nodes = (data || {}).agendas ? mapEdgesToNodes(data.agendas) : [];

  return (
    <Layout>
      <InformationNavbar />
      {nodes && <AgendaList nodes={nodes} />}
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default AgendaPage;
