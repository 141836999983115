import React from "react";
import PortableText from "../portableText";

const AgendaList = (props) => {
  return (
    <div className="mw9 center mt4 mb4 ph4-ns overflow-auto">
      <table className="f4 w-100 pa2" cellSpacing="0">
        <thead>
          <tr>
            <th className="fw6 bb b--black-20 tl pv3 pr3">No.</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Tanggal</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Kegiatan</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Deskripsi</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Lokasi</th>
            <th className="fw6 bb b--black-20 tl pv3 pr3">Yang Hadir</th>
          </tr>
        </thead>
        <tbody className="f4 lh-copy">
          {props.nodes.map((node, position) => (
            <tr key={node.id}>
              <td className="pv3 pr3 bb b--black-20">{position + 1}</td>
              <td className="pv3 pr3 bb b--black-20">{node.time}</td>
              <td className="pv3 pr3 bb b--black-20">{node.title}</td>
              <td className="pv3 pr3 bb b--black-20">
                <PortableText blocks={node._rawEvent} />
              </td>
              <td className="pv3 pr3 bb b--black-20">{node.location}</td>
              <td className="pv3 pr3 bb b--black-20">{node.attendee}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgendaList;
